<template>
  <div>
    <cbs-object
      v-if="objectFull"
      :objectid="objectid"
      :object-full="objectFull"
    />
    <cbs-data-object
      hide
      :objectid="objectid"
      @load="onObjectLoad"
    />
  </div>
</template>

<script>
import CbsObject from '@/cubus/components/object/CbsObject.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  components: {
    CbsObject,
    CbsDataObject,
  },
  data() {
    return {
      objectid: Number(this.$route.params.objectid),
      objectFull: null,
    }
  },
  created() {
    useCubus.setPageTitle(`Object ${this.objectid}`)
  },
  methods: {
    onObjectLoad(obj) {
      this.objectFull = obj
      if (obj && obj.object && obj.object.name) {
        useCubus.setPageTitle(obj.object.name)
      }
    },
  },
}
</script>

<style scoped>

</style>
